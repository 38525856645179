import React, { useState } from 'react';
//import './MyProfile.css'; // Import your CSS file for styling
import { ThemeProvider, useTheme } from './ThemeContext'; // Import the ThemeProvider and useTheme hooks

function MyProfile() {
  const [showPasswordForm, setShowPasswordForm] = useState(true);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const [showThemeOptions, setShowThemeOptions] = useState(false); // State variable to track visibility of theme options
  const { theme, toggleTheme } = useTheme(); // Access the theme and toggleTheme function from the useTheme hook

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      setErrorMessage('New password and confirm password must match.');
      return;
    }
    // Here you can add code to send the password change request to your backend
    console.log('Change password request:', { currentPassword, newPassword });
    // Clear form fields and error message
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setErrorMessage('');
  };

  

  const togglePasswordForm = () => {
    setShowPasswordForm(!showPasswordForm); // Toggle the state of showPasswordForm
  };

  const handleThemeChange = (e) => {
    toggleTheme(); // Toggle the theme
  };

  const toggleThemeOptions = () => {
    setShowThemeOptions(!showThemeOptions);
  };
  const handleLogout = () => {
    // Add logout logic here
  };

  return (
    <ThemeProvider> {/* Wrap your component with the ThemeProvider */}
      <div>
        
        <div className="p-4">
      <h2 className='m-0 text-xl text-slate-800 font-bold text-center cursor-pointer'>
        GENERAL SETTINGS
      </h2>
      <h3
        className='m-0 mt-8 font-medium cursor-pointer'
        onClick={togglePasswordForm}
      >
        Security & Privacy
      </h3>
    
      {/* Conditionally render the password form based on showPasswordForm state */}
      {showPasswordForm && (
        <div className="profile-change-password mt-4 p-4 bg-white rounded shadow-lg">
          <h2 className='m-0 text-slate-800 font-bold text-center cursor-pointer mb-4'>
            Change Password
          </h2>
          <form onSubmit={handleSubmit} className="password-form">
            <div className="form-group mb-4">
              <label htmlFor="currentPassword" className="form-label block text-gray-700 mb-2">Current Password</label>
              <input
                type="password"
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
                className="form-input w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="newPassword" className="form-label block text-gray-700 mb-2">New Password</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="form-input w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="confirmPassword" className="form-label block text-gray-700 mb-2">Confirm New Password</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="form-input w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <button type="submit" className="form-button w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600">Change Password</button>
            {errorMessage && <div className="error-message text-red-500 mt-2">{errorMessage}</div>}
          </form>
        </div>
      )}
      </div>
      <h3 className='mt-8 font-medium cursor-pointer text-red-500 hover:text-red-700' onClick={handleLogout}>
        Logout
      </h3>
      </div>
        <hr></hr>
     
        <div className="block1">
          <h2 className=' text-center font-bold'>ABOUT</h2>
          <p>One of the core functions of Git is the concepts of the Staging Environment, and the Commit.

          As you are working, you may be adding, editing and removing files. But whenever you hit a milestone or finish a part of the work, you should add the files to a Staging Environment.
          
          Staged files are files that are ready to be committed to the repository you are working on. You will learn more about commit shortly.
          
          For now, we are done working with index.html. So we can add it to the Staging Environment:</p>
        </div>
      
    </ThemeProvider> 
  );
}

export default MyProfile;
