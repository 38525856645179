import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext';




const MyDriversApp = () => {
    const { rootUrl } = require('../../config/config');
    const [drivers, setDrivers] = useState([]);
    const { isAuthenticated } = useAuth();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchBy, setSearchBy] = useState('name');
    const { user } = useAuth();
    const { theme } = useTheme(); 
    const [showAddDriverForm, setShowAddDriverForm] = useState(false);
    const [newDriver, setNewDriver] = useState({
        id:'',
        name: '',
        phone: '',
        id_no: '',
        license_no: '',
        license_expiry: '',
        referred_by:'',
        status:'',
        input_by:'',
        input_date: '',
        edit_date: '',
        edit_by: ''
        
       
    });
    const [errors, setErrors] = useState({
        id:'',
        name: '',
        phone: '',
        id_no: '',
        license_no: '',
        license_expiry: '',  
        referred_by:'',
        status:'',
        input_by:'',
        input_date: '',
        edit_date: '',
        edit_by: ''
        
        
    });
    const [editingDriver, setEditingDriver] = useState(null);
    const [viewingDriver, setViewingDriver] = useState(null);
    useEffect(() => {
        console.log('User:', user); // Check user information
        if (user && user.username) {
            const currentDate = new Date().toISOString(); // Get current date in ISO format
            setNewDriver((prevDriver) => ({
                ...prevDriver,
                input_by: user.username,
                input_date: currentDate,
                edit_by: user.username,
                edit_date: currentDate,
            }));
        }
    }, [user]);

    useEffect(() => {
        const fetchDrivers = async () => {
            const token = localStorage.getItem('authToken')|| 'default-token';

            try {
                const response = await axios.get(`${rootUrl}/drivers`,{
                    headers:{
                        'Authorization': `Bearer ${token}`

                    }
                });
                console.log('this is the token' , token)
                const data = response.data;
    
                const mappedData = data.map(item => {
                    
                    
         return {
                id: item.id || '',
                name: item.name || 'No name provided',
                phone: item.phone || 'No phone provided',
                id_no: item.id_no || 'Unknown',
                license_no: item.license_no || 'Unknown',
                timestamp: item.license_expiry ? new Date(item.license_expiry).getTime() : 0,
                referred_by: item.referred_by || 'Unknown',
                status: item.status || 'No status',
                input_by: item.input_by || 'Unknown',
                input_date: item.input_date ? new Date(item.input_date).toISOString().split('T')[0] : 'Unknown',
                edit_date: item.edit_date ? new Date(item.edit_date).toISOString().split('T')[0] : 'Unknown',
                edit_by: item.edit_by || 'Unknown'

                    };
                });
    
                setDrivers(mappedData);
                console.log('Mapped Data:', mappedData);
            } catch (error) {
                console.error("Error fetching messages: ", error);
            }
        };
    
        fetchDrivers();
    }, []);
    

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchByChange = (event) => {
        setSearchBy(event.target.value);
    };
    

    
    const handleAddDriver = async () => {
        let newErrors = {};

        if (!newDriver.name) newErrors.name = 'Car text is required';
        if (!/^\d{10}$/.test(newDriver.phone)) newErrors.phone = 'Phone must be exactly 10 digits'; 
        if (!newDriver.id_no) newErrors.id_no = 'identification number is required';
        if (!newDriver.license_no) newErrors.license_no = 'license  is required';
        if (!newDriver.license_expiry) newErrors.license_expiry = 'Date/time is required';      
        if (!newDriver.referred_by) newErrors.referred_by = 'Enter driver referred name'; 
        if (!newDriver.status) newErrors.status = 'no status!';
        if (!newDriver.input_by) newErrors.input_by = 'must fill in who inputted the data';
        if (!newDriver.input_date) newErrors.input_date = 'input date';
        if (!newDriver.edit_by) newErrors.edit_by ='fill in who edited';
        if (!newDriver.edit_date) newErrors.edit_date = 'fill in the date edited'


        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        try {
            const timestamp = new Date(newDriver.license_expiry).toISOString();
    
            const response = await axios.post(`${rootUrl}/drivers`, { ...newDriver, license_expiry: timestamp });
            const currentDate = new Date().toISOString(); // Get current date in ISO format
            const loggedInUser = user?.username; // Get the username from the AuthContext
    
            const addedDriver = {
                
                id: response.data.id,
                name: newDriver.name,
                phone: newDriver.phone, 
                id_no: newDriver.id_no, 
                license_no: newDriver.license_no,
                timestamp: new Date(timestamp).getTime(),
                referred_by: newDriver.referred_by,
                status: newDriver.status,
                input_by: newDriver.input_by ,
                input_date: newDriver.input_date,
                edit_by: newDriver.edit_by,
                edit_date: currentDate.edit_date

                
               
            };
    
            setDrivers([...drivers, addedDriver]);
            setShowAddDriverForm(false);
            setNewDriver({
                id:'',
                name:'',
                phone: '',
                id_no: '',
                license_no: '',
                license_expiry:'',
                referred_by:'',
                status: '',
                input_by:'',
                input_date: new Date().toISOString() || '',
                edit_by: '',
                edit_date:  new Date().toISOString() ||''
                
               
            });
    
            console.log('driver added:', addedDriver);
        } catch (error) {
            console.error("Error adding Driver: ", error);
        }
    };
    
    
    const handleEditDriver = (id) => {
        const driverToEdit = drivers.find(driver => driver.id === id);
     
        
        const formattedDate = new Date(driverToEdit.timestamp).toISOString().slice(0, 16);// datetime local fomart
        setEditingDriver({ ...driverToEdit, license_expiry: formattedDate });
    
    };
    
    const handleUpdateDriver = async () => {
        if (!editingDriver) return;
    
        let newErrors = {};
    
        if (!editingDriver.name) newErrors.name = 'Driver name is required';
        if (!/^\d{10}$/.test(editingDriver.phone)) newErrors.phone = 'Phone must be exactly 10 digits';
        if (!editingDriver.id_no) newErrors.id_no = 'National ID Number is required';
        if (!editingDriver.license_no) newErrors.license_no = 'License Number is required';
        if (!editingDriver.license_expiry) newErrors.license_expiry = 'Expiry Date/time is required';
        if (!editingDriver.referred_by) newErrors.referred_by = 'reference is required';
        if (!editingDriver.input_by) newErrors.input_by = 'must fill in who inputted the data';
        if (!editingDriver.input_date) newErrors.input_date = 'input date';
        if (!editingDriver.status) newErrors.status = 'status is required';
        if (!editingDriver.edit_by) newErrors.edit_by ='fill in who edited';
        if (!editingDriver.edit_date) newErrors.edit_date = 'fill in the date edited'
        
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        const { id,name, phone, id_no,license_no, license_expiry, referred_by, status, input_by, input_date, edit_by, edit_date } = editingDriver;
        const timestamp = new Date(license_expiry).toISOString();
    
        if (isNaN(Date.parse(timestamp))) {
            setErrors((prevErrors) => ({ ...prevErrors, time: 'Invalid date/time value' }));
            return;
        }
    
        try {
            await axios.put(`${rootUrl}/drivers ${id}`, { name, phone, id_no, license_no, license_expiry: timestamp, referred_by, status, input_by, input_date,edit_by, edit_date });
            const updatedDrivers = drivers.map(driver =>
                driver.id === id ? { ...driver, name, phone, id_no, license_no, timestamp: new Date(timestamp).getTime(),referred_by, status, input_by, input_date,edit_by, edit_date  } : driver
            );
            setDrivers(updatedDrivers);
            setEditingDriver(null);
    
            console.log('updatedDrivers:', updatedDrivers);
        } catch (error) {
            console.error("Error updating message: ", error);
        }
    };
    
    
    
    
    
    const handleDeleteDriver = async (id) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this car?');
        try {
            await axios.delete(`${rootUrl}/drivers/${id}`);
            const updatedDrivers = drivers.filter(driver => driver.id !== id);
            setDrivers(updatedDrivers);
        } catch (error) {
            console.error("Error deleting driver: ", error);
        }
    };

    const handleViewDriver = (id) => {
        const driverToView = drivers.find(driver => driver.id === id);
        setViewingDriver(driverToView);
    };

    const filteredDrivers = drivers.filter(driver => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        switch (searchBy) {
            case 'name':
                return driver.name.toLowerCase().includes(lowerCaseSearchTerm);
            case 'phone':
                return driver.phone.toLowerCase().includes(lowerCaseSearchTerm);
            case 'id_no':
                return driver.id_no.toLowerCase().includes(lowerCaseSearchTerm);
            case 'license_no':
                return driver.license_no.toLowerCase().includes(lowerCaseSearchTerm);
            case 'license expiry':
                return new Date(driver.timestamp).toLocaleString().toLowerCase().includes(lowerCaseSearchTerm);
            case 'referred_by':
                return driver.referred_by.toLowerCase().includes(lowerCaseSearchTerm);
            case 'status':
                return driver.status.toLowerCase().includes(lowerCaseSearchTerm);
            default:
                return false;
        }
    });

    return (
        <div className={`min-h-screen ${theme}`}>
            <div className="container mx-auto p-4">
                <h2 className="text-center text-2xl font-bold mb-4">Drivers Onboard</h2>

                <div className="flex justify-between items-center mb-4">
                    <div>
                        <label className="mr-2">Search By:</label>
                        <select className="border rounded px-2 py-1" value={searchBy} onChange={handleSearchByChange}>
                            
                            <option value="name">Driver Name</option>
                            <option value="phone">Phone</option>
                            <option value="id_no">ID number</option>
                            <option value="license_no">license Number</option>
                            <option value="license_expiry">License Expiry</option>
                            <option value="referred_by">Reffered By</option>
                            <option value="status">Status</option>
                            
                        </select>
                    </div>
                    <input
                        type="text"
                        placeholder={`Search by ${searchBy}`}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="border rounded px-2 py-1 w-1/3"
                    />
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-1 rounded"
                        onClick={() => setShowAddDriverForm(true)}
                    >
                        New Driver
                    </button>
                </div>
                   

                <table className="border-collapse w-full">
                    <thead>
                        <tr className="bg-slate-800 text-white">
                            <th className="border px-1 py-1 text-left">Driver Name</th>
                            <th className="border px-1 py-1 text-left">Phone</th>
                            <th className="border px-1 py-1 text-left">ID NO</th>
                            <th className="border px-1 py-1 text-left">Licence No</th>
                            <th className="border px-1 py-1 text-left">Licence Expiry</th>
                            <th className="border px-1 py-1 text-left">Reffered By:</th>
                            <th className="border px-1 py-1 text-left">Status</th>
                            <th className="border px-1 py-1 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDrivers.map((driver, index) => (
                            <tr key={driver.id} className={index % 2 === 0 ? "bg-white" : "bg-slate-200"}>
                                <td className="border px-2 py-2">{driver.name}</td>
                                <td className="border px-2 py-2">{driver.phone}</td>
                                <td className="border px-2 py-2">{driver.id_no}</td>
                                <td className="border px-2 py-2">{driver.license_no}</td>
                                <td className="border px-2 py-2">{new Date(driver.timestamp).toLocaleString()}</td>
                                <td className="border px-2 py-2">{driver.referred_by}</td>
                                <td className="border px-2 py-2">{driver["status"]}</td> 
                                <td className="border px-2  py-2">
                                    <button
                                        className="bg-slate-400  text-white px-2 py-1 rounded mr-2"
                                        onClick={() => handleEditDriver(driver.id)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="bg-red-700 text-white px-2 py-1 rounded mr-2"
                                        onClick={() => handleDeleteDriver(driver.id)}
                                       >
                                        Delete
                                        </button>
                                    <button
                                       className="bg-green-700 text-white px-2 py-1 rounded"
                                        onClick={() => handleViewDriver(driver.id)}
                                    >
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
             
               
            </div>

            {showAddDriverForm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded shadow-md w-96">
                        <h2 className="text-xl font-bold mb-4">New Driver</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="mb-1">
                            <label>Driver Name</label>
                            <input
                                autoComplete='off'
                                type="text"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.name}
                                onChange={(e) => setNewDriver({ ...newDriver, name: e.target.value })}
                            />
                            {errors.name && <p className="text-red-500">{errors.name}</p>}
                        </div>
                        <div className="mb-1">
                            <label>Phone</label>
                            <input
                                type="text"
                                autoComplete='off'
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.phone}
                                onChange={(e) => {
                                    const phoneValue = e.target.value;
                                    if (/^\d{0,10}$/.test(phoneValue)) {
                                        setNewDriver({ ...newDriver, phone: phoneValue });

                                        if(phoneValue.length === 10) {
                                        setErrors((prevErrors) => ({ ...prevErrors, phone: '' }));
                                    } else {
                                        setErrors((prevErrors) => ({ ...prevErrors, phone: 'Phone must be exactly 10 digits' }));
                                    }
                                  }
                                }}
                            />
                            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                        </div>
                        <div className="mb-1">
                            <label>ID number</label>
                            <input
                            
                                type="text"
                                autoComplete='off'
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.id_no}
                                onChange={(e) => setNewDriver({ ...newDriver , id_no: e.target.value })}
                            />
                            {errors.id_no && <p className="text-red-500">{errors.id_no}</p>}
                        </div>
                        <div className="mb-1">
                            <label>License No</label>
                            <input
                                type="text"
                                autoComplete='off'
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.license_no}
                                onChange={(e) => setNewDriver({ ...newDriver, license_no: e.target.value })}
                            />
                            {errors.license_no && <p className="text-red-500">{errors.license_no}</p>}
                        </div>
                        <div className="mb-1">
                            <label>License Expiry</label>
                            <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="datetime-local"
                            autoComplete='off'
                            value={newDriver.license_expiry}
                            onChange={e => setNewDriver({ ...newDriver, license_expiry: e.target.value })}
                        />

                            {errors.license_expiry && <p className="text-red-500">{errors.license_expiry}</p>}
                        </div>
                        
                        <div className="mb-1">
                            <label>Reffered By:</label>
                            <input
                                type="text"
                                autoComplete='off'
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.referred_by}
                                onChange={(e) => setNewDriver({ ...newDriver, referred_by: e.target.value })}
                            />
                            {errors.referred_by && <p className="text-red-500">{errors.referred_by}</p>}
                        </div>

                        <div className="mb-1">
                            <label>Status</label>
                            <select
                                type="text"
                                autoComplete='off'
                                className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.status}
                                onChange={(e) => setNewDriver({ ...newDriver, status: e.target.value })}
                             >
                                <option value="">select</option>
                                <option>AVAILABLE</option>
                                <option>OFF DUTY</option>
                                <option>ON BREAK</option>
                                <option>ON LEAVE</option>
                                <option>UNDER TRAINING</option>
                                <option>ASSIGNED</option>
                                <option>UNAVAILABLE</option>
                                <option>SUSPENDED</option>
                                <option>RETIRED</option>
                                <option>AWAITING ASSIGNMENT</option>
                             </select>
                            {errors.status && <p className="text-red-500">{errors.status}</p>}
                        </div>
                        <div className="mb-1">
                            <label>input By</label>
                            <input
                                type="text"
                                autoComplete='off'
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.input_by}
                                disabled
                            />
                            
                        </div>
                        <div className="mb-1">
                            <label>input Date</label>
                            <input
                                type="text"
                                autoComplete='off'
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.input_date}
                                disabled
                                
                            />
                           
                        </div>
                        <div className="mb-1">
                            <label>Edit Date</label>
                            <input
                                type="text"
                                autoComplete='off'
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.edit_date}
                                disabled
                            />
                            
                        </div>
                        <div className="mb-1">
                            <label>Edit By</label>
                            <input
                                type="text"
                                autoComplete='off'
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={newDriver.edit_by}
                                disabled
                            />
                           
                        </div>

                       
                        
                        
                        <button
                            className="bg-blue-500 hover:bg-blue-700 float-right text-white  rounded "
                            onClick={handleAddDriver}
                        >
                            Add
                        </button>
                        <button
                            className="bg-gray-500 hover:bg-gray-700 text-white  rounded float-right"
                            onClick={() => setShowAddDriverForm(false)}
                        >
                            Cancel
                        </button>
                    </div>
                    </div>
                </div>
            )}

            {editingDriver && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded shadow-md w-96 ">
                        <h2 className="text-xl font-bold mb-4">Edit Driver</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div>
                            <label>Driver Name</label>
                            <input
                                type="text"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={editingDriver.name}
                                onChange={(e) => setEditingDriver({ ...editingDriver, name: e.target.value })}
                            />
                            {errors.name && <p className="text-red-500">{errors.name}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Phone</label>
                            <input
                                type="text"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={editingDriver.phone}
                                onChange={(e) => {

                                    const phoneValue = e.target.value;

                                    if(/^\d{0,10}$/.test(phoneValue)){
                                        setEditingDriver({  ...editingDriver, phone: phoneValue});
                                        if(phoneValue.length === 10) {
                                        setErrors((prevErrors) => ({ ...prevErrors, phone: ''}));
                                    }else{
                                        setErrors((prevErrors) => ({ ...prevErrors, phone: 'phone must be exactly 10 digits'} ));
                                    }
                                  }
                                    
                                }}
                            />
                            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                        </div>
                        <div className="mb-2">
                            <label>ID number</label>
                            <input
                                type="text"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={editingDriver.id_no}
                                onChange={(e) => setEditingDriver({ ...editingDriver, id_no: e.target.value })}
                            />
                            {errors.id_no && <p className="text-red-500">{errors.id_no}</p>}
                        </div>
                        <div className="mb-2">
                            <label>License No</label>
                            <input
                                type="text"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={editingDriver.license_no}
                                onChange={(e) => setEditingDriver({ ...editingDriver, license_no: e.target.value })}
                            />
                            {errors.license_no && <p className="text-red-500">{errors.license_no}</p>}
                        </div>
                        <div className="mb-2">
                            <label>License Expiry</label>
                            <input
                                type="datetime-local"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={editingDriver.license_expiry}
                                onChange={(e) => setEditingDriver({ ...editingDriver, license_expiry: e.target.value })}
                            />
                            {errors.license_expiry && <p className="text-red-500">{errors.license_expiry}</p>}
                        </div>
                       
                        <div className="mb-2">
                            <label>Reffered By:</label>
                            <input
                                type="text"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={editingDriver.referred_by}
                                onChange={(e) => setEditingDriver({ ...editingDriver, referred_by: e.target.value })}
                            />
                            {errors.referred_by && <p className="text-red-500">{errors.referred_by}</p>}
                        </div>

                        <div className="mb-2">
                           <label>Status</label>
                             <select
                                type='text'
                                className=" border rounded w-full py-2 px-3 text-gray-700 "
                                value={newDriver.status}
                                onChange={(e) => setNewDriver({ ...newDriver, status: e.target.value })}
                             >
                                <option>select status</option>
                                <option></option>
                            
                             </select>
                               {errors.status && <p className="text-red-500">{errors.status}</p>}
                        </div>

                        
                        
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mr-2"
                            onClick={handleUpdateDriver}
                        >
                            Update
                        </button>
                        <button
                            className="bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded"
                            onClick={() => setEditingDriver(null)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                </div>
                
            )}

            {viewingDriver && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded shadow-md w-96">
                        <h2 className="text-xl font-bold mb-4  text-slate-600 text-center">View Driver</h2>
                        <div className="mb-2">
                            <label className='font-bold' >Driver Name</label>
                            <p >{viewingDriver.name}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold' >Phone</label>
                            <p >{viewingDriver.phone}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>ID Number</label>
                            <p>{viewingDriver.id_no}</p>
                        </div>
                        <div className="mb-2">
                            <label  className='font-bold'>license Number</label>
                            <p>{viewingDriver.license_no}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>license Expiry</label>
                            <p>{new Date(viewingDriver.timestamp).toLocaleString()}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Reffered By:</label>
                            <p>{viewingDriver.referred_by}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Status </label>
                            <p>{viewingDriver.status}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Input by </label>
                            <p>{viewingDriver.input_by}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>input date </label>
                            <p>{viewingDriver.input_date}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Edit Date</label>
                            <p>{viewingDriver.edit_date}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Edited By </label>
                            <p>{viewingDriver.edit_by}</p>
                        </div>
                        
                      
                        <button
                            className="bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded"
                            onClick={() => setViewingDriver(null)}
                        >
                            Close
                        </button>
                    </div>
                </div> 
            )}
        </div>
    );
};

export default MyDriversApp;
