import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import useAuth

const Home = () => {
    const { isAuthenticated } = useAuth(); // Call useAuth to get isAuthenticated

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <h1 className="text-3xl font-bold mb-4">Welcome to Cosmo Fleets!</h1>
            <p className="text-lg mb-6">Your all-in-one solution for managing your fleet business.</p>
            <div className="flex space-x-4">
                {!isAuthenticated && (
                    <>
                        <Link 
                            to="/login" 
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                        >
                            Login
                        </Link>
                        <Link 
                            to="/signup" 
                            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                        >
                            Sign Up
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
}

export default Home;
