// config.js
const rootUrl = 'https://moneyminder.codewithme.co.ke';
const apiVersion = 'v1';
const timeout = 5000; // Example of a numeric value
const isProduction = true; // Example of a boolean value

module.exports = {
  rootUrl,
  apiVersion,
  timeout,
  isProduction
};
