import React, { useState, useContext } from 'react';
import ContactContext from './ContactContext';
import { useTheme } from './ThemeContext';
//import './MyContact.css';
import axios from 'axios'

function MyContact() {
  const { contacts, addContact, deleteContact, editContact } = useContext(ContactContext);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [search, setSearch] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [panelVisible, setPanelVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [selectedContactIndex, setSelectedContactIndex] = useState(null);
  const { theme, toggleTheme } = useTheme();

  const handleAddContact = () => {
    if (name.trim() !== '' && phone.trim() !== '') {
      if (editingIndex !== null) {
        editContact(editingIndex, { name, phone, email });
        setEditingIndex(null);
      } else {
        addContact({ name, phone, email });
      }
      setName('');
      setPhone('');
      setEmail('');
      setPanelVisible(false); 
    }
  };

  const handleEditContact = (index) => {
    const contactToEdit = contacts[index];
    setName(contactToEdit.name);
    setPhone(contactToEdit.phone);
    setEmail(contactToEdit.email || '');
    setEditingIndex(index);
    setPanelVisible(true); 
  };

  const handleDeleteContact = (index) => {
    deleteContact(index);
  };

  const handleSendMessage = (index) => {
    setShowMessageForm(true);
    setSelectedContactIndex(index);
  };

  const handleSubmitMessage = () => {
   
    const contactToSend = contacts[selectedContactIndex];
    const messageData = {
        contactName:  contactToSend.name ,
        message : message
  
  };
  axios.post('https:example.com/api/send-message:' ,messageData)
    .then(response=>{
      console.log('message sent successfully:', response.data);
      setMessage('');
      setShowMessageForm(false);
    })
    .catch(error =>{
      console.error('error sending message:', error);
    });
  };

  const filteredContacts = contacts.filter((contact) => {
    return (
      contact.name.toLowerCase().includes(search.toLowerCase()) ||
      contact.phone.includes(search) ||
      (contact.email && contact.email.toLowerCase().includes(search.toLowerCase()))
    );
  });

  const togglePanelVisibility = () => {
    setPanelVisible(!panelVisible);
  };

  return (
    <div>
      <h1 className='text-white text-2xl text-center justify-center m-5'>Contact Manager</h1>
      <div className=" mb-20 text-align:center border-slate-400 border-solid bg-blue-400 p-1 w-11/12">
        <input
        className='rounded-md w-11/12 p-2 justify-center text-xm ms-5'
          type="text"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className=" my-5 mr-5 ">
        {filteredContacts.map((contact, index) => (
          <div key={index} className="contact-item rounded-md mt-2.5 items-center bg-blue-400 flex justify-between p-2.5">
            <div>
              <h3 className='m-0 text-white font-bold'>{contact.name}</h3>
              <p className='mb-1.5 text-white font-bold '>{contact.phone}</p>
              {contact.email && <p className='mb-1.5 text-white font-bold'>{contact.email}</p>}
            </div>
            <div>
              <button className='Contact-button bg-slate-800 text-white p-1 m-1 hover:bg-slate-600 rounded-md' onClick={() => handleSendMessage(index)}>Message</button>
              <button className='Contact-button bg-slate-800 text-white p-1 m-1 hover:bg-slate-600 rounded-md' onClick={() => handleEditContact(index)}>Edit</button>
              <button className='Contact-button bg-slate-800  text-white p-1 m-1 rounded-md hover:bg-red-600' onClick={() => handleDeleteContact(index)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
      <button className="add-contact absolute top-2 ml-1 right-1 justify-center text-center p-1 mb-4 rounded-lg text-white font-bold bg-blue-400 " onClick={togglePanelVisibility}>
        {panelVisible ? 'Close' : 'Add new'}
      </button>
      {panelVisible && (
                 <form>
        <div className="add-contact-popup fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2   bg-slate-800 p-5 border border-gray-300 shadow-lg text-white">
          <div className="form-container flex flex-col">
 
            <input className='mb-2.5 text-slate-800 p-2'
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input className='mb-2.5 text-slate-800 p-2'
              type="text"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input className='mb-2.5 text-slate-800 p-2'
              type="email"
              placeholder="Email (optional)"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div>
              <button className="button-container flex-end m-2.5  bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600" onClick={handleAddContact}>
                {editingIndex !== null ? 'Update' : 'Save'}
              </button>
              <button className="button-container  flex-end m-2.5  bg-gray-500 text-white px-4 py-2 rounded hover:bg-red-600 " onClick={togglePanelVisibility}>Cancel</button>
            </div>
          </div>
        </div>
        </form>
      )}
      {showMessageForm && (
        <form>
      <div className="popup fixed w-full h-full top-0 left-0 flex justify-center items-center bg-gray-800 bg-opacity-50 overflow-hidden">
  <div className="popup-content bg-blue-400 p-6 border border-gray-300 shadow-lg rounded-lg relative">
    <span className="close absolute top-2 right-2 text-2xl cursor-pointer" onClick={() => setShowMessageForm(false)}>&times;</span>
    <h2 className="text-xl font-semibold mb-4">Send Message</h2>
    <textarea
      className="w-full p-2 border border-gray-300 rounded mb-4"
      placeholder="Enter your message..."
      value={message}
      onChange={(e) => setMessage(e.target.value)}
    />
    <div className="flex justify-between">
      <button className="button-container bg-slate-800 text-white px-4 py-2 rounded hover:bg-blue-600" onClick={handleSubmitMessage}>Send</button>
      <button className="button-container bg-slate-800 text-white px-4 py-2 rounded hover:bg-red-600" onClick={() => setShowMessageForm(false)}>Discard</button>
    </div>
  </div>
</div>

     </form>        
      )}
    </div>
  );
}

export default MyContact;