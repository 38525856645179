// AuthButton.js
import React from 'react';
import { useAuth } from './AuthContext';
import { Link, useNavigate } from 'react-router-dom';

const AuthButton = () => {
    const { isAuthenticated, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/'); // Redirect to home page after logout
    };

    return isAuthenticated ? (
        <button onClick={handleLogout} className=" text-white px-4 py-3 rounded-lg hover:text-red-600">
            Logout
        </button>
    ) : (
        <Link to="/login" className="text-sm text-blue-500 hover:underline">
            Login
        </Link>
    );
};

export default AuthButton;
