import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/myTrack/AuthContext'; // Import useAuth
import ProtectedRoute from './components/myTrack/ProtectedRoute';
import MyTrackApp from './components/myTrack/MyTrack';
import Home from './components/myTrack/home';
import MyCarsApp from './components/myTrack/MyCars';
import MyDriversApp from './components/myTrack/MyDrivers';
import MyContactApp from './components/myTrack/MyContact';
import MyProfileApp from './components/myTrack/MyProfile';
import AuthButton from './components/myTrack/AuthButton';
import Login from './components/myTrack/login';
import Signup from './components/myTrack/signup';
import { ContactProvider } from './components/myTrack/ContactContext';
import { ThemeProvider } from './components/myTrack/ThemeContext';
import './index.css';

const rootUrl = 'https://fleet.codewithme.co.ke/';


function App() {
  return (
    <AuthProvider>
      <Router>
        <ThemeProvider>
          <ContactProvider>
            <Navigation />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route
                path="/mytrack"
                element={
                  <ProtectedRoute element={<MyTrackApp />} />
                }
              />
              <Route
                path="/contacts"
                element={
                  <ProtectedRoute element={<MyContactApp />} />
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute element={<MyProfileApp />} />
                }
              />
              <Route
                path="/cars"
                element={
                  <ProtectedRoute element={<MyCarsApp />} />
                }
              />
              <Route
                path="/drivers"
                element={
                  <ProtectedRoute element={<MyDriversApp />} />
                }
              />
            </Routes>
          </ContactProvider>
        </ThemeProvider>
      </Router>
    </AuthProvider>
  );
}

const Navigation = () => {
  const { isAuthenticated } = useAuth(); // Get authentication status

  return (
    <nav className='bg-slate-800 w-full'>
      <strong>
        <ul className='bg-slate-800 w-full list-none m-0 p-0'>
          <li className='hover:text-blue-400 text-white inline-block text-xl rounded-md p-2 cursor-pointer'>
            <Link to="/">Home</Link>
          </li>
          {isAuthenticated && (
            <>
             <li className='text-xl hover:text-blue-400 text-white inline-block'>
             <Link to="/mytrack">Messages</Link>
             </li>
             
              <li className='text-xl hover:text-blue-400 text-white inline-block ml-3'>
                <Link to="/cars">Cars</Link>
              </li>
              <li className='text-xl hover:text-blue-400 text-white inline-block ml-3'>
                <Link to="/drivers">Drivers</Link>
              </li>
              <li className='text-xl text-white inline-block ml-3 float-right'>
                <AuthButton /> {/* Add AuthButton to navigation */}
              </li>
            </>
          )}
          {!isAuthenticated && (
            <>
              <li className='hover:text-blue-400 text-white text-xl p-2 cursor-pointer float-right'>
                <Link to="/login">Login</Link>
              </li>
              <li className='hover:text-blue-400 text-white text-xl p-2 cursor-pointer float-right'>
                <Link to="/signup">Sign-up</Link>
              </li>
            </>
          )}
           
        </ul>
      </strong>
    </nav>
  );
}

export default App;
