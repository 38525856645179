import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import  axios  from 'axios';

const Signup = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // To programmatically navigate

    //const USER_REGEX= /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
    //const PWD_REGEX =/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

    const handleSignup = async (e) => {
        e.preventDefault();
        
        if (!username || !email || !password) {
            setError('All fields are required.');
            return;
        }

        setError(''); // Clear previous errors

        try {
            const response = await axios.post('/register', {
                body: JSON.stringify({ username, email, password }),
                
                headers: {
                    'Content-Type': 'application/json',
                },
                
            });

            const data = await response.json();

            if (response.ok) {
                // Handle successful signup, e.g., navigate to login or another page
                navigate('/login');
            } else {
                setError(data.message || 'Signup failed.');
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
        }
    }

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm">
                <h1 className="text-2xl font-bold mb-6 text-center">Sign Up</h1>
                <form onSubmit={handleSignup}>
                    <div className="mb-4">
                        <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">Username:</label>
                        <input 
                            type="text" 
                            id="username" 
                            placeholder="Enter Username" 
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)} 
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email:</label>
                        <input 
                            type="email" 
                            id="email" 
                            placeholder="Enter Email" 
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">Password:</label>
                        <input 
                            type="password" 
                            id="password" 
                            placeholder="Enter Password" 
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                        />
                    </div>
                    {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                    <div className="flex items-center justify-between">
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600">Sign Up</button>
                        <Link to="/login" className="text-sm text-blue-500 hover:underline">Already have an account? Log in</Link>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Signup;
